import React from 'react';

import Layout from '../../layout/ConsultantLayout';
import BiddingStatusList from '../../views/consultant/biddingStatusListView';
const BiddingStatus = (): React.ReactElement => {
  return (
    <Layout>
      <BiddingStatusList></BiddingStatusList>
    </Layout>
  );
};

export default BiddingStatus;
